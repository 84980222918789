code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand > img {
  width: 10rem;
}

.navbar-expand .navbar-brand > img {
  width: 10rem;
}


@media (min-width: 992px) {
  .vh-lg-120 {
    height: 120vh;
  }
}
